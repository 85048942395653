<template>
  <div class="modal-presets">
    <!-- <pre>{{form}}</pre> -->

    <i class="el-icon-close modal-presets__icon" @click="$emit('delete', index)"></i>

    <div class="modal-presets__item" v-for="(value, key, index) of form" :key="index">
      <FileLoader
        v-if="renderItems.indexOf(key) != -1 && isShow"
        :value="form[key] && form[key].file"
        :name="key"
        :type="key == 'preset' ? 'file' : 'image'"
        :image="value && value.url ? value.url : value"
        :index="index"
        :isShow="isShow"
        @input="value => handleChangeFile(value, key)"
      />
    </div>

    <!-- <CRow> -->
      <CCol sm="2">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Free"
          :checked="form.free"
          @update:checked="(value) => handleChangeFree({value, key: 'free'})"
        />
      </CCol>

      <CCol sm="3">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Free Android"
          :checked="form.free_android"
          @update:checked="(value) => handleChangeFree({value, key: 'free_android'})"
        />
      </CCol>

      <CCol sm="2">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Curtain"
          :checked="form.curtain"
          @update:checked="(value) => handleChangeFree({value, key: 'curtain'})"
        />
      </CCol>

      <CCol sm="3">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Show Button"
          :checked="form.show_button"
          @update:checked="(value) => handleChangeFree({value, key: 'show_button'})"
        />
      </CCol>

      <CCol sm="2">
        <CInput
          :value="form.order"
          @input="(value) => handleChangeOrder(value)"
          placeholder="Order"
        />
      </CCol>
    <!-- </CRow> -->


    <CRow>
      <CCol sm="6">
        <CInput
          label="Link"
          :value="form.link"
          @input="(value) => handleChangeFree({value, key: 'link'})"
          placeholder="Link"
        />
      </CCol>

      <CCol sm="6">
        <CInputCheckbox
          class="modal-presets__checkbox"
          label="Unique Text Show"
          :checked="form.unique_text_show"
          @update:checked="(value) => handleChangeFree({value, key: 'unique_text_show'})"
        />
      </CCol>
    </CRow>

    <CRow v-if="form.unique_text_show">
      <CCol sm="6" v-for="lang in languages" :key="`unique_text_${lang}`">
        <CInput
          :label="`Unique text ${lang}`"
          :value="form[`unique_text_${lang}`]"
          @input="(value) => handleChangeFree({value, key: `unique_text_${lang}`})"
          :placeholder="`Unique text ${lang}`"
        />
      </CCol>
    </CRow>

    <ModalImageEnlargement
      v-if="showModalImageEnlargement"
      :image="selectedImage"
      @close="showModalImageEnlargement = false"
    />
  </div>
</template>

<script>
import FileLoader from "@/components/root/FileLoader";

import axios from "axios";

import ModalImageEnlargement from "@/components/modals/ModalImageEnlargement";

export default {
  name: "modal-presets",

  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },

    index: {
      type: [String, Number],
      default: 0
    },

    isShow: {
      type: Boolean,
      default: true
    }
  },

  components: {
    FileLoader,

    ModalImageEnlargement
  },

  data: () => ({
    showModalImageEnlargement: false,
    selectedImage: null,

    renderItems: ["reference", "image", "preset"],

    form: {
      reference: null,
      image: null,
      preset: null,
      free: false,
      free_android: false,
      show_button: true,
      curtain: true,
      order: 0,
      unique_text_show: false,
      unique_text_en: null,
      unique_text_ru: null,
      unique_text_fr: null,
      unique_text_es: null,
      unique_text_de: null,
      unique_text_it: null,
      unique_text_pt: null
    },

    imageResize: null,
    showModalResize: false,

    languages: ["en", "ru", "fr", "es", "de", "it", "pt"],

    // formChange: {
    //   file1: null,
    //   file2: null,
    //   file3: null,
    //   file4: null,
    //   file5: null
    // },
  }),

  watch: {
    // isShow(value) {
    //   if (!value) {
    //     this.form = {
    //       reference: null,
    //       image: null,
    //       preset: null
    //     };
    //   }
    // },

    data(value) {
      console.log("chanfeData", value);

      this.form = value;
    }
  },

  created() {
    this.form = this.data;
  },

  methods: {
    handleChangeFile(file, key) {
      console.log("handleChangeFile");

      if (!key) key = this.activeImage.key;

      this.form[key] = {
        type: "upload",
        file,
        url: URL.createObjectURL(file)
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    },

    handleChangeFree({ value, key }) {
      console.log("handleChangeFree", { value, key });
      this.form[key] = value;

      this.$emit("change", this.form);
    },

    handleChangeOrder(value) {
      this.form.order = value;

      this.$emit("change", this.form);
    },

    handlerLoadFile() {
      var file = this.$refs.file.files[0];

      if (file) {
        console.log(file);

        this.handleChangeFile(file);

        // this.link = URL.createObjectURL(file);
        // this.$emit("input", file);
      }
    },

    handleUpdate({ id, index }) {
      this.activeImage = {
        key: `file${index + 1}`
      };

      this.$refs.file.click();
    },

    handleShowImage(image) {
      this.showModalImageEnlargement = true;
      this.selectedImage = image;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-presets {
  width: 100%;

  margin-top: 1rem;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;

  position: relative;

  border: 1px solid #768192;

  &__icon {
    position: absolute;
    right: 10px;
    margin-top: 5px;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }
  }

  &__checkbox {
    width: 100%;
  }
}
</style>
